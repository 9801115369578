<template>
  <div>
    <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="474"
      shaped
      v-if="PayLinkParcel"
    >
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title>Parcel Payment</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="PayLinkParcel.pickUpLocation.Street"
              label="Pickup Address"
              filled
              disabled
              shaped
            ></v-text-field>
            <v-text-field
              v-model="PayLinkParcel.offlineSenderDetails.firstName"
              label="Sender Name"
              filled
              disabled
            ></v-text-field>
            <v-text-field
              v-model="PayLinkParcel.offlineSenderDetails.phoneNumber"
              label="Sender Mobile"
              filled
              disabled
            ></v-text-field>
            <v-text-field
              v-model="PayLinkParcel.pickUpDate"
              label="Pickup Date"
              filled
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="PayLinkParcel.parcelDetails[0].Street"
              label="Delivery Address"
              filled
              disabled
              shaped
            ></v-text-field>
            <v-text-field
              v-model="PayLinkParcel.parcelDetails[0].name"
              label="Receiver Name"
              filled
              disabled
            ></v-text-field>
            <v-text-field
              v-model="PayLinkParcel.parcelDetails[0].mobile"
              label="Receiver Mobile"
              filled
              disabled
            ></v-text-field>
            <v-text-field
              v-model="PayLinkParcel.parcelDetails[0].deliveryType"
              label="Delivery Type"
              filled
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>

      <v-card-title>
        ShippingFee :
        {{ DisplayCurrency(PayLinkParcel.shippingFee) }}
      </v-card-title>
      <v-card-title v-if="PayLinkParcel.PaymentStatus != 'Pending'">
        Payment Status :
        {{ PayLinkParcel.PaymentStatus }}
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <Payment
          v-if="PayLinkParcel.PaymentStatus == 'Pending'"
          :PayLinkParcel="PayLinkParcel"
        />
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card-title v-else>No Parcel Found</v-card-title>
  </div>
</template>
<script>
import currencyFormatter from "currency-formatter";
import Payment from "../components/PaymentLink.vue";
export default {
  data: () => ({
    loading: false,
    selection: 1,
  }),

  components: {
    Payment,
  },

  created() {
    this.GetParcel(this.$route.params.id);
  },
  computed: {
    PayLinkParcel() {
      return this.$store.getters.PayLinkParcel;
    },
  },
  methods: {
    GetParcel(parcelId) {
      let payload = {
        parcelId: parcelId,
      };
      this.$store.dispatch("GetParcel", payload);
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
  },
};
</script>