<template>
  <paystack
    :amount="parseFloat(PayLinkParcel.shippingFee) * 100"
    :email="'payment@haulee.ng'"
    :paystackkey="paystackkey"
    :reference="reference"
    :callback="callback"
    :close="close"
    :embed="false"
  >
    <i class="fas fa-money-bill-alt"></i>
    <v-btn block color="success"> Card Payment </v-btn>
  </paystack>
</template>
 
<script type="text/javascript">
import paystack from "vue-paystack";
import Config from "../Config";
export default {
  props: ["PayLinkParcel"],
  components: {
    paystack,
  },
  data() {
    return {
      paystackkey: Config.payStackPublicKey, //paystack public key
    };
  },
  computed: {
    reference() {
      let text = "FDW";
      let possible = Date.now();
      return text + possible + Math.floor(Math.random() * 5);
    },
  },
  methods: {
    callback: function (response) {
      if (response.status == "success") {
        let payload = {
          parcelId: this.PayLinkParcel._id,
          reference: response.reference,
        };
        this.$store.dispatch("ParcelPayment", payload);
      }
    },
    close: function () {
      this.$store.commit("setSnackbar", true);
      this.$store.commit("setServerMessage", "Payment closed");
    },
  },
};
</script>